import React from 'react'
import GalleryBackground from '../../components/galleryBackground'
import GalleryGridFancy from '../../components/galleryGridFancy'
import adRo from "../../images/rollovers/graphic-design/ad-ro.png"
import "../layout.css"

import img1 from '../../images/graphic-design/advertisement/1.jpg'
import img2 from '../../images/graphic-design/advertisement/2.jpg'
import img3 from '../../images/graphic-design/advertisement/3.jpg'
import img4 from '../../images/graphic-design/advertisement/4.jpg'
import img5 from '../../images/graphic-design/advertisement/5.jpg'
import img6 from '../../images/graphic-design/advertisement/6.jpg'
import img7 from '../../images/graphic-design/advertisement/7.jpg'
import img8 from '../../images/graphic-design/advertisement/8.jpg'
import img9 from '../../images/graphic-design/advertisement/9.jpg'
import img10 from '../../images/graphic-design/advertisement/10.jpg'
import img11 from '../../images/graphic-design/advertisement/11.jpg'
import img12 from '../../images/graphic-design/advertisement/12.jpg'
import img13 from '../../images/graphic-design/advertisement/13.jpg'
import img14 from '../../images/graphic-design/advertisement/14.jpg'
import img15 from '../../images/graphic-design/advertisement/15.jpg'
import img16 from '../../images/graphic-design/advertisement/16.jpg'
import img17 from '../../images/graphic-design/advertisement/17.jpg'
import img18 from '../../images/graphic-design/advertisement/18.jpg'
import img19 from '../../images/graphic-design/advertisement/19.jpg'
import img20 from '../../images/graphic-design/advertisement/20.jpg'
import img21 from '../../images/graphic-design/advertisement/21.jpg'
import img22 from '../../images/graphic-design/advertisement/22.jpg'
import img23 from '../../images/graphic-design/advertisement/23.jpg'

export default function Advertistements() {
    let images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23]
    return (
        <GalleryBackground title="Advertisements" imgSrc={adRo}>
            <GalleryGridFancy imgArray={images} />
        </GalleryBackground>
    )
}